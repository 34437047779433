<template>
  <div class="stacked-images" v-if="images.length > 0">
    <div class="images-container" :id="tipId || 'test'">
      <template v-for="(item, index) in imagesList">
        <img
          :key="index"
          class="stacking-image shadow-sm"
          :src="type === 'order' ? item.image : item"
          :alt="type === 'order' ? 'Vendor image' : 'Product Image'"
        />
      </template>
    </div>
    <template v-if="type === 'order'">
      <b-tooltip :target="tipId || 'test'" placement="bottom">
        <div class="tooltopstuff">
          <h6 v-for="(i, idx) in imagesList" :key="idx">
            {{ i.name }}
          </h6>
        </div>
      </b-tooltip>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    imagesList: []
  }),
  props: {
    images: Array,
    type: String,
    tipId: {
      type: String,
      default: null
    }
  },
  created() {
    this.imagesList = this.images.map(i =>
      this.type === "order" ? { image: i.image, name: i.name } : i
    );
  },

  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  }
};
</script>

<style lang="scss" scoped>
.stacked-images {
  .images-container {
    position: relative;
    min-height: 35px;
    min-width: 35px;
    .stacking-image {
      display: inline-block;
      aspect-ratio: 1;
      width: 35px;
      position: absolute;
      inset: 0;
      border-radius: 50%;
      overflow: hidden;
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          z-index: 6 - $i;
          border: 2px solid rgb(random(255), random(255), random(255));
          left: #{($i - 1) * 7}px;
        }
      }
    }
  }
}
</style>
